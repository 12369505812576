import BaseService from '@/services/BaseService'

class WarehouseService extends BaseService {
  constructor() {
    super()
  }

  async getWarehouses(opts = {}) {
    this.endPoint = 'api/v2/warehouses'
    const response = await this.get(opts)
    return response.data
  }

  getOneWarehouse(id: any, opts = {}) {
    this.endPoint = 'warehouses'
    return this.getOne(id, opts).then(response => {
      return response.data
    })
  }

  async addNewWarehouse(data = {}) {
    this.endPoint = 'api/v2/warehouses'
    const response = await this.post(data)
    return response.data
  }

  async editWarehouse(id: any, data: any) {
    this.endPoint = 'api/v2/warehouses'
    const response = await this.putOne(id, data)
    return response.data
  }

  async deleteWarehouse(id: any) {
    this.endPoint = 'api/v2/warehouses'
    const response = await this.delete(id)
    return response.data
  }
}

export default new WarehouseService()
