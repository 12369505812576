import BaseService from '@/services/BaseService'

class ProvinceService extends BaseService {
  constructor() {
    super()
  }

  async getProvinces(opts = {}) {
    this.endPoint = '/api/v2/provinces'
    return this.get(opts)
  }

  async getOneProvinces(Id, opts = {}) {
    this.endPoint = 'api/v2/provinces'
    return this.getOne(Id, opts)
  }

  async setProvinces(data = {}) {
    this.endPoint = 'api/v2/provinces'
    return this.post(data)
  }

  async deleteProvince(id) {
    this.endPoint = 'api/v2/provinces'
    return this.delete(id)
  }

  async updateProvince(id, data = {}) {
    this.endPoint = 'api/v2/provinces'
    return this.putOne(id, data)
  }
}
export default new ProvinceService()
