import BaseService from '@/services/BaseService'

class CityService extends BaseService {
  constructor() {
    super()
  }

  async getCities(opts = {}) {
    this.endPoint = '/api/v2/cities'
    return this.get(opts)
  }

  async getOneCities(Id, opts = {}) {
    this.endPoint = 'api/v2/cities'
    return this.getOne(Id, opts)
  }

  async setCities(data = {}) {
    this.endPoint = 'api/v2/cities'
    return this.post(data)
  }

  async deleteCities(id) {
    this.endPoint = 'api/v2/cities'
    return this.delete(id)
  }

  async updateCities(id, data = {}) {
    this.endPoint = 'api/v2/cities'
    return this.putOne(id, data)
  }
}
export default new CityService()
