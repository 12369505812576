import BaseService from '@/services/BaseService'

class BrandService extends BaseService {
  constructor(){
    super()
  }
  getBrandsLists(opts = {}){
    this.endPoint = 'api/v2/brands'
    return this.get(opts).then(response =>{
      return response.data
    })
  }

  deleteBrands(id: any){
    this.endPoint = 'api/v2/brands'
    return this.delete(id).then(response => {
      return response.data
    })
  }

  createBrand(data: any){
    this.endPoint = 'api/v2/brands'
    return this.post(data).then(response => {
      return response.data
    })
  }

  updateBrand(id: any, data: any) {
    this.endPoint = 'api/v2/brands'
    return this.putOne(id, data).then(response => {
      return response.data
    })
  }

}
export default new BrandService()