// node_modules dependencies
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Dropzone from 'vue2-dropzone'
import Trumbowyg from 'vue-trumbowyg'
import { Quill, quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import pullAt from 'lodash/pullAt'
import remove from 'lodash/remove'
import random from 'lodash/random'
import split from 'lodash/split'
import replace from 'lodash/replace'
import findIndex from 'lodash/findIndex'
import trimStart from 'lodash/trimStart'
import join from 'lodash/join'
import kebabCase from 'lodash/kebabCase'
import sortBy from 'lodash/sortBy'
import '../../../../node_modules/trumbowyg/dist/plugins/table/trumbowyg.table'
import debounce from 'lodash/debounce'

// Base Page
import BasePage from '@/pages/Base'

// Services
import ProductService from '@/services/Products'
import SupplierService from '@/services/Suppliers'
import WarehouseService from '@/services/Warehouses/warehouses'
import CountryService from '@/services/Country'
import ProvinceService from '@/services/Province'
import CitiesService from '@/services/City'
import BrandsService from '@/services/Brands'
import CurrencyService from '@/services/Currency'

// Configs
import config from '@/configs'
import find from 'lodash/find'

// Global Constant Variables
const storage = window.localStorage
const baseUrl = config.api.baseURL
Quill.register('modules/imageResize', ImageResize)

@Component({
  components: { Dropzone, quillEditor, Trumbowyg }
})
export default class CreateProduct extends BasePage {
  constructor() {
    super()
  }
  //modal upload URL
  openModalUrl: boolean = false

  // imgplaceholder : any = require('../../../assets/img/placeholderimg.jpg')
  viaPlaceholder: any = 'https://via.placeholder.com/150'

  // Token
  token: string = storage.getItem('access_token')

  // Title
  title: string = ''
  maxTitleCharacter: number = 500
  titleHint: string = 'This is the title for your product'

  // Description
  content: string = ''
  editorOptions: object = {
    modules: {
      imageResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar']
      },
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ font: [] }, { color: [] }, { background: [] }],
        [{ direction: 'rtl' }],
        [{ align: [] }],
        ['link', 'image', 'video'],
        ['clean']
      ]
    },
    placeholder: 'Add product description here...',
    theme: 'snow'
  }

  config: object = {
    advanced: {
      autogrow: true,
      removeformatPasted: true,
      // btnsDef: {
      //   save: {
      //     fn: () => {
      //       this.onUpdateDescription();
      //     },
      //     text: "Save Changes",
      //     hasIcon: false,
      //     class: "custom-save-btn"
      //   }
      // },
      // btnsAdd: ["save"],
      btns: [
        ['viewHTML'],
        ['undo', 'redo'],
        ['formatting'],
        ['strong', 'em', 'del'],
        // ["link"],
        // ["insertImage"],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen'],
        ['tableAddRow'],
        ['tableAddColumn'],
        ['tableDeleteRow'],
        ['tableDeleteColumn'],
        ['table'],
        ['save']
      ]
    }
  }

  // Currency
  currency: object[] = [
    {
      currencyName: 'IDR',
      currencySymbol: 'Rp'
    },
    {
      currencyName: 'USD',
      currencySymbol: '$'
    },
    {
      currencyName: 'CNY',
      currencySymbol: '¥'
    },
    {
      currencyName: 'THB',
      currencySymbol: '฿'
    }
  ]
  // currencySelected: string = ''
  currencyHint: string = 'Select your currency'

  // Price
  regularPrice: string = ''
  regularPriceHint: string = 'Your regular price'
  maxRegularPriceValue: number = 1000000000000 // 1 Triliun
  salePrice: string = ''
  salePriceHint: string = 'Your sale price'
  maxSalePriceValue: number = 1000000000000 // 1 Triliun
  resellerPrice: string = ''
  resellerPriceHint: string = 'Reseller Price'
  maxResellerPriceValue: number = 1000000000000

  // Quantity
  quantity: string = ''
  quantityHint: string = 'Your product quantity'
  maxQuantityValue: number = 1000000 // 1 Million
  quantityUnit: any = [
    {
      text: 'Pcs',
      value: 'pcs'
    },
    {
      text: 'Sheets',
      value: 'sheets'
    },
    {
      text: 'Box',
      value: 'box'
    },
    {
      text: 'Crt',
      value: 'crt'
    },
    {
      text: 'Lsn',
      value: 'lsn'
    },
    {
      text: 'Pak',
      value: 'pak'
    },
    {
      text: 'Pack',
      value: 'pack'
    },
    {
      text: 'Set',
      value: 'set'
    },
    {
      text: 'Kg',
      value: 'kg'
    },
    {
      text: 'Ton',
      value: 'ton'
    },
    {
      text: 'Rol',
      value: 'rol'
    },
    {
      text: 'Bag',
      value: 'bag'
    },
    {
      text: 'Mtr',
      value: 'mtr'
    },
    {
      text: 'Dzn',
      value: 'dzn'
    },
    {
      text: 'Ltr',
      value: 'ltr'
    }
  ]
  quantityUnitSelected: string = ''
  quantityUnitHint: string = 'Select quantity unit'

  // Weight
  weight: string = ''
  weightHint: string = 'Your product weight'
  maxWeightValue: number = 1000000 // 1 Million
  weightUnit: object[] = [
    {
      text: 'Kgs',
      value: 'kg'
    },
    {
      text: 'Gram',
      value: 'gr'
    },
    {
      text: 'Pounds',
      value: 'lbs'
    },
    {
      text: 'Ounce',
      value: 'oz'
    }
  ]
  weightUnitSelected: string = ''
  weightUnitHint: string = 'Select weight unit'

  // Dimension
  length: string = ''
  lengthHint: string = 'Your product length'
  maxLengthValue: number = 1000000 // 1 Million
  width: string = ''
  widthHint: string = 'Your product width'
  maxWidthValue: number = 1000000 // 1 Million
  height: string = ''
  heightHint: string = 'Your product height'
  maxHeightValue: number = 1000000 // 1 Million
  dimensionUnit: object[] = [
    {
      text: 'Meter',
      value: 'm'
    },
    {
      text: 'Centimeter',
      value: 'cm'
    },
    {
      text: 'Feet',
      value: 'ft'
    },
    {
      text: 'Inch',
      value: 'inch'
    }
  ]
  dimensionUnitSelected: string = ''
  dimensionUnitHint: string = 'Select dimension unit'

  // Domestic Shipping
  domesticShipping: number = null

  // Quantity PerCBM
  quantityPerCBM: number = null

  //Product SKU
  product_originalSku: string = null
  product_aliSku: string = null

  // Variants
  variants: any = []
  generatedVariants: any = []
  generatedVariantPanelContent = null
  maxSkuValue: number = 1000000000000 // 1 Triliun

  // Minimum Quantity
  minimumQuantityOrder: number = null

  //video url
  video_url: string = null

  // supplier note
  supplier_note: string = ''

  // country origin
  country_origin: string = null

  // Isexclusive
  is_Exclusive: boolean = false;

  // Wholesale Prices & Bulk Prices
  showWholesale_bulkDisk: boolean = false
  wholesalePrices: any = []
  bulkPrices: any = []

  radioWholesale: any = ''
  showWholesaleandBulkPrice_Dialog: boolean = false

  enableWholesalePrice: any = {
    openButtonAddNew: false,
    maxWholesaleBulkDiscount: 3
  }
  enableBulkPrice: any = {
    showButtonAddNew: false,
    maxBulkPrice: 3
  }

  bandleUnit: object[] = [
    {
      text: 'CRT',
      value: 'crt'
    },
    {
      text: 'LSN',
      value: 'lsn'
    },
    {
      text: 'BOX',
      value: 'box'
    },
    {
      text: 'PAK',
      value: 'pak'
    },
    {
      text: 'SET',
      value: 'set'
    },
    {
      text: 'GRS',
      value: 'grs'
    }
  ];
  itemsBulkQuantityUnit: any = [
    { text: 'Lusin', unit: 'lusin' },
    { text: 'Box', unit: 'box' },
    { text: 'Kodi', unit: 'kodi' },
    { text: 'Carton', unit: 'carton' },
    { text: 'Gross', unit: 'gross' },
    { text: 'Dozen', unit: 'dozen' },
    { text: 'Rim', unit: 'rim' },
    { text: 'Pack', unit: 'pack' }
  ]

  // Images
  options: any = {
    url: 'https://httpbin.org/post', // dummy post data. this will change later after adding an image
    headers: {
      Authorization: 'Bearer ' + this.token,
      Identifier: 'manage'
    },
    addRemoveLinks: true,
    dictDefaultMessage: 'Click Here or Drop Image Here To Upload',
    autoProcessQueue: false,
    acceptedFileTypes: 'image/*',
    maxNumberOfFiles: 10,
    maxFileSizeInMB: 2,
    duplicateCheck: true,
    thumbnailHeight: 250,
    thumbnailWidth: 250,
    paramName: 'images'
  }

  urlImage: string = null
  urlImageTemp: any = []

  //Categories
  topCategory: number = null
  categoryLevel2: number = null
  categoryLevel3: number = null
  opts: any = {
    params: {
      'filter[level][is]': '1',
      'include': 'child',
      'page[limit]' : '100'
    }
  }
  topCategories: any = []
  categoriesLevel2: any = []
  categoriesLevel3: any = []

  loadingCategories: boolean = false
  loadingLevel2Categories: boolean = false
  loadingLevel3Categories: boolean = false

  noLevel2Categories: boolean = false
  noLevel3Categories: boolean = false

  // Selected as main images
  selectedMainImages: any = {}
  haveMainImage: boolean = false


  @Watch('topCategory', { deep: true })
  onChangedTopCategory(to: string, from: string) {
    this.getNextCategories(to, true)
  }
  @Watch('categoryLevel2', { deep: true })
  onChangedChildCategory(to: string, from: string) {
    this.getNextCategories(to)
  }

  @Watch('radioWholesale')
  onChangedWholesale() {
    if (this.radioWholesale == 'wholesalePrice') this.onEnableWholesalePrice()
    if (this.radioWholesale == 'bulkPrice') this.onEnableBulkPrice()
  }

  async getCategories() {
    try {
      this.loadingCategories = true
      const responseCategories = await ProductService.getTopLevelCategories(this.opts)
      forEach(responseCategories.data.data, category => {
        let categoryMap = {
          categoryName: category.attributes.name,
          categoryId: category.attributes.id
        }

        this.topCategories.push(categoryMap)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.loadingCategories = false
    }
  }

  async getNextCategories(categoryId, topCategory = false) {
    try {
      //set parentId
      let parentId = categoryId

      if (topCategory) {
        this.loadingLevel2Categories = true
        this.noLevel2Categories = false
        this.noLevel3Categories = false
        this.categoriesLevel2 = []
        this.categoriesLevel3 = []
      } else {
        this.loadingLevel3Categories = true
        this.noLevel3Categories = false
        this.categoriesLevel3 = []
      }

      const opts: any = {
        params: {
          'page[limit]' : '100'
        }
      }
      const responseData = await ProductService.getLowLevelCategories(parentId, opts)

      if (responseData.data.data.length > 0) {
        //assign response data to global object
        forEach(responseData.data.data, category => {
          let categoryMap = {
            categoryName: category.attributes.name,
            categoryId: category.attributes.id
          }

          if (topCategory) {
            this.categoriesLevel2.push(categoryMap)
            this.loadingLevel2Categories = false
          } else {
            this.categoriesLevel3.push(categoryMap)
            this.loadingLevel3Categories = false
          }
        })
      } else {
        //No category dimmers if no data
        if (topCategory) {
          this.loadingLevel2Categories = false
          this.noLevel2Categories = true
        } else {
          this.loadingLevel3Categories = false
          this.noLevel3Categories = true
        }
      }
    } catch (error) {
      this.catchHandler(error)
    }
  }

  // Label
  labelNames: any = [
    {
      text: 'Retail',
      value: 2
    },
    {
      text: 'Wholesale',
      value: 1
    },
    {
      text: 'Hot Product',
      value: 3
    },
    {
      text: 'Local',
      value: 4
    },
    {
      text: 'Imported Product',
      value: 5
    },
    {
      text: 'Fulfillment',
      value: 6
    },
    {
      text: 'COD',
      value: 9
    }
  ]
  labelVirtualName: any = [
    {
      text: 'This is Virtual Product',
      value: 8
    }
  ]
  selectedLabel: any = []

  idUser: any = []
  getClientLoading: boolean = false
  clientItem: any = []
  keyword: string = null
  searchWarehouseDebounce: Function;
  warehouseItems: any = []
  warehouseDialogData: any = {
    loading: false,
    keyword: null
  }
  warehouseId: any = null
  warehouseHint: string = 'Select warehouse';
  // searchDebounce: Function

  async getClient() {
    this.getClientLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }

      if (!isEmpty(this.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await SupplierService.getClient(opts)
      const dataClient = response.data.data

      for (const clientList of dataClient) {
        const client: any = {
          name: clientList.attributes.name + ' (' + clientList.attributes.email + ')',
          id: clientList.attributes.id
        }
        this.clientItem.push(client)
      }

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getClientLoading = false
    }
  }

  // Currencies
  currencies: any = [];
  currencySelected: string = '';
  currencySelectedName: string = '';
  currencyKeyword: string = '';
  currencyNameHint: string = 'Select currency';
  getCurrenciesLoading: boolean = false;

  async getCurrencies() {
    try {
      this.getCurrenciesLoading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253,
          'filter[currency][like]': this.currencyKeyword
          // Calling all country. Reducing constant filter request to the server
        }
      }

      if (isEmpty(this.currencyKeyword)) {
        delete opts.params['filter[currency][like]']
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      // console.log("getCurrencies() responseData", responseData)
      this.currencies = []
      for (const currency of responseData) {
        this.currencies.push(currency.attributes)
      }
      this.currencies = sortBy(this.currencies, 'currency')

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCurrenciesLoading = false
    }
  }

  // Country
  countries: object[] = []
  countrySelected: string = ''
  getCountriesLoading: boolean = false
  countryNameHint: string = 'Select country'

  async getCountries() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data

      for (const country of responseData) {
        this.countries.push(country.attributes)
      }
      this.countries = sortBy(this.countries, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = false
    }
  }

  // Provinces
  provinces: any = []
  provinceSelected: string = ''
  getProvinceLoading: boolean = false
  provinceKeyword: string = null

  async getProvinces(countryId) {
    this.getProvinceLoading = true
    try {
      const opts: any = {
        params: { 'filter[country_id][is]': countryId }
      }

      if (!isEmpty(this.provinceKeyword)) {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.provinceKeyword
        }
      } else {
        let textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      await ProvinceService.getProvinces(opts)
        .then(response => {
          for (const province of response.data.data) this.provinces.push(province.attributes)
        })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getProvinceLoading = false
    }
  }

  // Cities
  cities: any = []
  citySelected: string = ''
  getCityLoading: boolean = false
  cityKeyword: string = null

  async getCities(provinceId) {
    this.getCityLoading = true
    try {
      const opts: any = {
        params: { 'filter[province_id][is]': provinceId }
      }

      if (!isEmpty(this.cityKeyword)) {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.cityKeyword
        }
      } else {
        let textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      await CitiesService.getCities(opts)
        .then(response => {
          for (const citie of response.data.data) this.cities.push(citie.attributes)
        })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCityLoading = false
    }
  }

  // Supplier
  supplierNames: any = []
  supplierSelected: string = ''
  supplierKeyword: string = ''
  getSupplierLoading: boolean = false
  supplierHint: string = 'Select supplier'

  async getSupplier() {
    try {
      this.getSupplierLoading = true
      const opts: any = {
        params: { include: 'city' }
      }

      if (!isEmpty(this.supplierKeyword)) {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.supplierKeyword
        }
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await SupplierService.getSuppliers(opts)

      forEach(response.data, supplier => {
        if (supplier.attributes.city_id != null) {
          const detail = {
            text: supplier.attributes.name + ', ' + response.included.city[supplier.attributes.city_id].attributes.name + ' ' + response.included.country[supplier.attributes.country_id].attributes.name,
            value: supplier.attributes.id
          }
          this.supplierNames.push(detail)
        } else {
          const detail = {
            text: supplier.attributes.name,
            value: supplier.attributes.id
          }
          this.supplierNames.push(detail)
        }
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getSupplierLoading = false
    }
  }

  // Brand
  // brandNameOnly:
  brandNames: any = [];
  brandSelected: string = '';
  brandsKeyword: string = '';
  getBrandsLoading: boolean = false;
  brandHint: string = 'Select brand';

  async getBrands() {
    // console.log("getBrands()")
    try {
      this.getBrandsLoading = true
      const opts: any = {
        params: {
          // include: "city"
        }
      }

      if (!isEmpty(this.brandsKeyword)) {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.brandsKeyword
        }
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await BrandsService.getBrandsLists(opts)
      // console.log("getBrands", response)

      forEach(response.data, brand => {
        const detail = {
          text: brand.attributes.name,
          value: brand.attributes.id
        }
        this.brandNames.push(detail)
      })

      // this.supplierNames.push(detail);


    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getBrandsLoading = false
    }
  }

  // Table product rules
  tableHeaders: object[] = [
    {
      text: 'Rule Name',
      align: 'center',
      sortable: true,
      value: ''
    },
    {
      text: 'Rule Weight',
      align: 'center',
      sortable: true,
      value: ''
    },
    {
      text: 'Volumetric Weight',
      align: 'right',
      sortable: true,
      value: ''
    },
    {
      text: 'Implementations Methods',
      align: 'center',
      sortable: true,
      value: ''
    },
    {
      text: 'Quantity (PCS) / CBM',
      align: 'right',
      sortable: true,
      value: ''
    },
    {
      text: 'Dimensions ( W x H x L )',
      align: 'center',
      sortable: true,
      value: ''
    }
  ]

  // product id get from sending data
  productId: string | number = ''

  get editor() {
    return (this.$refs as any).descriptionEditor.quill
  }
  get allVariantsName() {
    return map(this.variants, 'variantName')
  }
  get allGeneratedVariantsName() {
    return map(this.generatedVariants, 'variantName')
  }
  get currencyNameOnly() {
    return map(this.currency, 'currencyName')
  }
  get brandNameOnly() {
    return map(this.brandNames, 'brandName')
  }
  get quantityUnitOnly() {
    return find(this.quantityUnit, item => item.value == this.quantityUnitSelected)
  }

  mounted() {
    this.getCurrencies()
    this.getClient()
    this.getCategories()
    this.getSupplier()
    this.getCountries()
    this.getWarehouses()
  }

  @Watch('keyword')
  onChanged() {
    this.getClient()
  }
  @Watch('brandsKeyword')
  onBrandChanged() {
    this.getBrands()
  }
  @Watch('supplierKeyword')
  onSupplierChanged() {
    this.getSupplier()
  }
  @Watch('provinceKeyword')
  onProvincesChanged() {
    this.getProvinces(this.countrySelected)
  }
  @Watch('cityKeyword')
  onCitiesChanged() {
    this.getCities(this.provinceSelected)
  }

  @Watch('currencySelected')
  onChangeCurrency() {
    const selectedCurrency = find(
      this.currencies,
      option => option.id === this.currencySelected
    )
    this.currencySelectedName = selectedCurrency ? selectedCurrency.currency : ''
  }

  @Watch('currencyKeyword')
  onChangecurrencyKeyword() {
    this.getCurrencies()
  }

  @Watch('warehouseDialogData.keyword')
  async onChangedWarehouse() {
    this.$vuetify.goTo(0)
    if(this.warehouseDialogData.keyword == ''){
      this.warehouseId = null
      this.searchWarehouseDebounce = debounce(this.getWarehouses, 500)
      this.searchWarehouseDebounce()
    }else if(this.warehouseId == null || (find(this.warehouseItems, item => item.id == this.warehouseId) && find(this.warehouseItems, item => item.id == this.warehouseId).name != this.warehouseDialogData.keyword)){
      this.searchWarehouseDebounce = debounce(this.getWarehouses, 500)
      this.searchWarehouseDebounce()
    }
  }

  async getWarehouses() {
    this.warehouseItems = []
    this.warehouseDialogData.loading = true
    try {
      // if (this.supplierDialogData.countryOrigin === '') {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.warehouseDialogData.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.warehouseDialogData.keyword
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await WarehouseService.getWarehouses(opts)

      const dataWarehouse = response.data

      for (const warehouseList of dataWarehouse) {
        const warehouse: any = {
          name: warehouseList.attributes.name,
          id: warehouseList.attributes.id
        }
        this.warehouseItems.push(warehouse)
      }
      // }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.warehouseDialogData.loading = false
    }
  }

  // Bulk/Wholesale Price
  onEnableWholesalePrice() {
    this.salePrice = ''
    this.enableBulkPrice.showButtonAddNew = false

    if (this.wholesalePrices.length < 1)
      this.addWholesalePrice()
    this.enableWholesalePrice.openButtonAddNew = true
  }

  onEnableBulkPrice() {
    this.salePrice = ''
    this.enableWholesalePrice.openButtonAddNew = false

    if (this.bulkPrices.length > 1)
      this.addBulkPrice()
    this.enableBulkPrice.showButtonAddNew = true
  }

  addWholesalePrice() {
    if (this.wholesalePrices.length < this.enableWholesalePrice.maxWholesaleBulkDiscount) {
      this.wholesalePrices.push({
        minimumQuantity: '',
        wholesalePrice: ''
      })
      if (this.wholesalePrices.length < this.enableWholesalePrice.maxWholesaleBulkDiscount) {
        this.enableWholesalePrice.openButtonAddNew = true
      } else this.enableWholesalePrice.openButtonAddNew = false
    }
  }

  addBulkPrice() {
    if (this.bulkPrices.length < this.enableBulkPrice.maxBulkPrice) {
      this.bulkPrices.push({
        type: 'bulk_price',
        action: 'create',
        dimension_unit: this.dimensionUnitSelected,
        weight_unit: this.weightUnitSelected,
        dimensions: [
          { label: 'length', value: null },
          { label: 'width', value: null },
          { label: 'height', value: null }
        ],
        weight: this.weight
      })
      if (this.bulkPrices.length < this.enableBulkPrice.maxBulkPrice) {
        this.enableBulkPrice.showButtonAddNew = true
      } else this.enableBulkPrice.showButtonAddNew = false
    }
  }

  removeWholesalePrice(wholesalePriceIndex) {
    this.wholesalePrices.splice(wholesalePriceIndex, 1)
    this.enableWholesalePrice.openButtonAddNew = true
  }

  removeBulkPrice(index) {
    this.bulkPrices.splice(index, 1)
    this.enableBulkPrice.showButtonAddNew = true
  }

  // Variants
  addNewVariant() {
    this.variants.push({
      variantName: '',
      variantNameHint: 'ex: Size, Color, Material',
      variantNameError: [],
      variantItems: [],
      variantItemsHint: 'Add as many items as you need. Press enter to generate the variants',
      variantItemsError: [],
      variantImages: []
    })
  }
  // when new chip inside variant items is added
  generateVariantItems(variantIndex) {
    if (!isEmpty(this.variants[variantIndex])) {
      this.generatedVariants = []
      forEach(this.addVariantItems(this.variants, variantIndex), variant => {
        this.generatedVariants.push({
          variantName: variant,
          quantity: this.quantity == '' ? '' : this.quantity,
          price: this.regularPrice == '' ? '' : this.regularPrice,
          salePrice: this.salePrice == '' ? '' : this.salePrice,
          resellerPrice: this.resellerPrice == '' ? '' : this.resellerPrice,
          original_sku: '',
          ali_sku: '',
          imageUrl: this.viaPlaceholder
        })
      })
      // create duplicate-free array
      if (this.variants[variantIndex].variantItems !== undefined) {
        this.variants[variantIndex].variantItems = uniq(this.variants[variantIndex].variantItems)
        forEach(this.variants[variantIndex].variantItems, (dataVariantItem, index) => {
          this.variants[variantIndex].variantImages[index] = this.viaPlaceholder
        })
      }
    }
  }

  addVariantItems(variants: any, variantIndex) {
    const add = ([{ variantName, variantItems }, ...variants]: any, row = []) => {
      let uniqVariant = uniq(variantItems) // create duplicate-free array
      return [].concat(
        ...uniqVariant.map(variantItem => {
          const currRow = [...row, `${variantName}:&nbsp;<b>${variantItem}</b>`]
          return variants.length ? add(variants, currRow) : currRow.join('<br>') // instead of currRow.join(', ') replace with [currRow] if you want an array
        })
      )
    }
    return add(variants)
  }
  getAllVariantsNameExcept(variantIndex) {
    let allVariantName = map(this.variants, 'variantName') // get all variant name only
    let filter = pullAt(allVariantName, variantIndex) // drop current position
    return allVariantName // return all variant name without current index
  }
  removeVariant(variantIndex) {
    this.variants.splice(variantIndex, 1)
    isEmpty(this.variants) ? this.removeGeneratedVariants() : this.generateVariantItems(variantIndex)
    this.generateVariantItems(variantIndex)
  }
  removeGeneratedVariants() {
    remove(this.generatedVariants)
  }
  removeVariantItems(variantIndex, itemIndex) {
    this.variants[variantIndex].variantItems.splice(itemIndex, 1)
    this.variants[variantIndex].variantImages.splice(itemIndex, 1)
    isEmpty(this.variants[variantIndex].variantItems)
      ? this.removeVariant(variantIndex)
      : this.generateVariantItems(variantIndex)
    this.generateVariantItems(variantIndex)
  }

  // Images
  imageAdded(file) {
    // set main image when image clicked
    if (file.id === undefined) {
      file.id = file.upload.uuid
    }
    if (file.default) {
      this.setMainImage(file)
    }

    file.previewElement.addEventListener('click', () => {
      this.setMainImage(file)
      if (!isEmpty(this.selectedMainImages)) {
        this.showSnackbar({
          text: `${file.name} is set as main image`,
          color: 'green',
          timeout: 2000
        })
      }
    })
    // end set main image when image clicked
  }
  imageAddedError(file) {
    this.error()

    if (file.type != 'image/jpg' && file.type != 'image/jpeg' && file.type != 'image/png') {
      ; (this.$refs as any).images.removeFile(file)
      this.showSnackbar({ text: 'Error! Not an image file(color: s)', timeout: '' })
    } else if (file.size > this.options.maxFileSizeInMB * 1024 * 1024) {
      ; (this.$refs as any).images.removeFile(file)
      this.showSnackbar({
        text: `Error! Image(s) size is too large. Max ${this.options.maxFileSizeInMB} MB`,
        color: 'red',
        timeout: 5000
      })
    } else if ((this.$refs as any).images.getQueuedFiles().length >= this.options.maxNumberOfFiles) {
      ; (this.$refs as any).images.removeFile(file)
      this.showSnackbar({
        text: `Error! Image(s) too much. Max ${this.options.maxNumberOfFiles} Images`,
        color: 'red',
        timeout: 5000
      })
    }
  }
  imageDuplicate(file) {
    this.showSnackbar({ text: 'Image is already added', color: 'orange', timeout: 2000 })
  }
  beforeSendingImage(file, xhr, formData) {
    formData.append('type', 'image')
    // set main image
    if (file === this.selectedMainImages) {
      formData.append('is_default', 1)
    } else {
      formData.append('is_default', 0)
    }
    // end set main image
  }
  beforeSendingImageMultiple(file, xhr, formData) {
    formData.append('type', 'image')
  }
  imageUploadSuccess(file, response) {
    if ((this.$refs as any).images.getUploadingFiles().length <= 0) this.done()
  }
  imageUploadMultipleSuccess(files, response) {
    if ((this.$refs as any).images.getUploadingFiles().length <= 0) this.done()

  }
  imageUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) { }

  // Form Submitted
  submit() {
    // Check validation
    this.$validator.validateAll().then(response => {
      if (response) {
        this.showLoading({ text: 'Creating Product...' })

        // Product Attributes
        let attributes = []
        forEach(this.allVariantsName, (valueName, index) => {
          forEach(this.variants[index].variantItems, (valueItem, i) => {
            attributes.push({
              key: valueName,
              value: valueItem,
              image_url: this.variants[index].variantImages[i],
              property: `${valueName}:${valueItem};${random(0, this.toUnixMillisecondTimestamp())}`,
              original_key: valueName,
              original_value: valueItem
            })
          })
        })

        // Product SKUS
        let skus = []
        forEach(this.generatedVariants, (data, index) => {
          // Items
          let items = []
          forEach(split(this.generatedVariants[index].variantName, '<br>'), variantName => {
            let key = trimStart(split(variantName, ':')[0])
            let value = replace(replace(split(variantName, ':')[1], '&nbsp;<b>', ''), '</b>', '')
            let uniqueId = findIndex(attributes, object => {
              return object.key === key && object.value === value
            })
            let property = attributes[uniqueId].property
            items.push({ key, value, property })
          })
          skus.push({
            items,
            sku_id: this.generatedVariants[index].original_sku,
            ali_sku: this.generatedVariants[index].ali_sku,
            price: this.generatedVariants[index].price,
            sale_price: this.generatedVariants[index].salePrice,
            reseller_price: this.generatedVariants[index].resellerPrice,
            price_strike: this.generatedVariants[index].salePrice,
            quantity: this.generatedVariants[index].quantity,
            image_url: this.generatedVariants[index].imageUrl,
            properties: join(map(items, 'property'), '-')
          })
        })

        // Wholesale/Bulk Discount
        let wholesale_prices = []

        if (this.radioWholesale == 'wholesalePrice') {
          forEach(this.wholesalePrices, (data, index) => {
            let countwholesalePrices = this.wholesalePrices.length - 1

            if (index == countwholesalePrices.toString()) {
              let attributes = {
                min_quantity: data.minimumQuantity,
                max_quantity: 0,
                price: data.wholesalePrice
              }

              wholesale_prices.push({ attributes })
            } else {
              let attributes = {
                min_quantity: data.minimumQuantity,
                max_quantity: this.wholesalePrices[index + 1].minimumQuantity - 1,
                price: data.wholesalePrice
              }

              wholesale_prices.push({ attributes })
            }
          })
        }

        // Selected Category
        let selectedCategory = [
          this.topCategory,
          this.categoryLevel2,
          this.categoryLevel3
        ]

        let formattedSelectedCategory = selectedCategory.filter(categoryId => categoryId !== null)

        const selectedCurrencyForPayload = find(
          this.currencies,
          option => option.id === this.currencySelected
        )

        // Final JSON data that sent to API
        let newProduct = {
          attributes,
          skus,
          title: this.title,
          description: this.content,
          // old
          // original_currency: this.currencySelected,
          original_currency: selectedCurrencyForPayload.currency,
          regular_price: this.regularPrice,
          sale_price: this.salePrice,
          reseller_price: this.resellerPrice,
          quantity: Number(this.quantity),
          unit: this.quantityUnitSelected,
          weight: this.weight,
          weight_unit: this.weightUnitSelected,
          keyword: kebabCase(this.title),
          stock_status: 'Recently Added',
          categories: formattedSelectedCategory,
          tags: this.selectedLabel,
          supplier_id: this.supplierSelected,
          country_id: this.countrySelected,
          province_id: this.provinceSelected,
          city_id: this.citySelected,
          dimensions: [
            {
              label: 'width',
              value: this.width
            },
            {
              label: 'length',
              value: this.length
            },
            {
              label: 'height',
              value: this.height
            }
          ],
          dimension_unit: this.dimensionUnitSelected,
          user_ids: this.idUser,
          minimum_qty: this.minimumQuantityOrder,
          video_url: this.video_url,
          wholesale_prices,
          original_language: null,
          original_title: this.title,
          original_description: null,
          original_url: null,
          domestic_shipping: this.domesticShipping,
          qty_per_cbm: this.quantityPerCBM,
          sku: this.product_originalSku,
          ali_sku: this.product_aliSku,
          relationships: this.bulkPrices,
          enable_pricing: this.showWholesale_bulkDisk,
          pricing_type: this.radioWholesale,
          is_exclusive: this.is_Exclusive,
          brand_id: this.brandSelected,
          note: this.supplier_note,
          original_country: this.country_origin,
          warehouses: this.warehouseId
        }

        // console.log("submit newProduct", newProduct)

        // Send Product to API
        ProductService.createNewProduct(newProduct)
          .then(response => {
            // Send Images
            if ((this.$refs as any).images.getQueuedFiles().length > 0) {
              this.productId = response.data.data.attributes.id
              this.showLoading({ text: 'Uploading Images...' })
              ; (this.$refs as any).images.setOption('url', `${baseUrl}api/v2/products/${this.productId}/images`)
              ; (this.$refs as any).images.processQueue()
            } else {
              this.done()
            }
            this.submitUrlImage(response.data.data.attributes.id)
            // console.log(response,'response');
          })
          .catch(error => this.catchHandler(error))
      } else {
        this.showSnackbar({
          text: 'Please fill all the required field(s)',
          color: 'red',
          timeout: 3500
        })
        this.generatedVariantPanelContent = 0
      }
    })
    this.closeLoading()
  }

  done() {
    this.showSnackbar({ text: 'Product successfully created', color: 'green', timeout: 2000 })
    this.closeLoading()
    setTimeout(() => {
      this.$router.push('/products')
    }, 2000)
  }

  error() {
    this.showSnackbar({
      text: 'Error! Something Happened. Check your internet connection',
      color: 'red',
      timeout: 4000
    })
  }

  // Variant Items Image
  editVariantItemsImageDialog: boolean = false
  dataVariantItemsImage: any = {
    items: []
  }
  fileImage: any = []

  imgplaceholder: any = require('../../../assets/img/placeholderimg.jpeg')
  variant_index: number = null
  // Variant Items Image
  editVariantItemsImage(variantIndex) {
    this.variant_index = variantIndex
    this.editVariantItemsImageDialog = true
    this.dataVariantItemsImage.items = []
    forEach(this.variants[variantIndex].variantItems, (variantItem, index) => {
      const item = {
        name: this.variants[variantIndex].variantName,
        option: variantItem,
        imageUrl: this.variants[variantIndex].variantImages[index] != undefined ? this.variants[variantIndex].variantImages[index] : this.viaPlaceholder
      }
      this.dataVariantItemsImage.items.push(item)
      if (this.variants[variantIndex].variantImages[index] === undefined) {
        this.variants[variantIndex].variantImages[index] = this.viaPlaceholder
      }
    })
  }

  saveVariantItemsImageDialog() {
    this.editVariantItemsImageDialog = false
  }

  imageFile: any = []

  onSelectedVariantImages(event, index) {
    this.imageFile = event.target.files[0]
    this.dataVariantItemsImage.items[index].imageUrl = URL.createObjectURL(this.imageFile)
    this.dataVariantItemsImage.items[index].imageFile = this.imageFile
    let configimg = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
        Identifier: 'manage'
      }
    }
    let data = new FormData()

    data.append('image', this.dataVariantItemsImage.items[index].imageFile)

    ProductService.setOptionValueImage(data, configimg).then(response => {
      this.dataVariantItemsImage.items[index].imageUrl = response.data.image_url
      this.variants[this.variant_index].variantImages[index] = response.data.image_url
    })
  }

  onSelectedSkuImages(e, index) {
    const image = e.target.files[0]
    let configimg = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
        Identifier: 'manage'
      }
    }
    let data = new FormData()
    data.append('image', image)

    ProductService.setSkuImage(data, configimg).then(response => {
      this.generatedVariants[index].imageUrl = response.data.image_url
    })
  }

  // set main image
  setMainImage(file) {
    if (!isEmpty(file)) {
      if (isEmpty(this.selectedMainImages)) {
        this.selectedMainImages = file
        this.haveMainImage = true
        file.previewElement.classList.add('selected-main')
      } else if (file.id !== this.selectedMainImages.id) {
        this.selectedMainImages.previewElement.classList.remove('selected-main')
        this.selectedMainImages = file
        this.haveMainImage = true
        file.previewElement.classList.add('selected-main')
      } else if (file.id === this.selectedMainImages.id) {
        this.selectedMainImages.previewElement.classList.remove('selected-main')
        this.selectedMainImages = {}
        this.haveMainImage = false
      }
    } else {
      this.haveMainImage = false
    }
  }
  // end set main image

  //open Modal
  modalImageUrl() {
    this.urlImage = ''
    this.openModalUrl = true
  }

  uploadUrl() {
    // console.log(this.urlImageTemp, 'atas');
    this.openModalUrl = false
    // console.log(this.urlImage.split(';'));
    forEach(this.urlImage.split(';'), split => {
      this.urlImageTemp.push(split)
    })
    // console.log(this.urlImageTemp, 'bawah');
    var img = ''
    forEach(this.urlImage.split(';'), (image, index) => {
      img =
        '<img src=\'' +
        image +
        '\' style=\'max-width:100% !important;vertical-align:middle;margin-bottom:10px;\' />'
      let mockFile: any = {
        name: `${index}_${image
          .split('/')
          .pop()
          .split('#')[0]
          .split('?')[0]
        }`,
        id: index,
        accepted: true,
        kind: 'image',
        status: 'fetched',
        type: 'image/jpeg',
        upload: {
          filename: image
            .split('/')
            .pop()
            .split('#')[0]
            .split('?')[0]
        },
        default: image
          ? true
          : false
      };
      (this.$refs as any).images.manuallyAddFile(
        mockFile,
        image,
        () => {
          console.log('Callback')
        },
        null,
        {
          dontSubstractMaxFiles: true,
          addToFiles: true
        }
      );
      (this.$refs as any).images.dropzone.emit(
        'thumbnail',
        mockFile,
        image
      )
    })
  }

  cancelUrl() {
    this.openModalUrl = false
  }

  async submitUrlImage(id) {
    let data =
    {
      data: [
        {
          id: id,
          relationships:
            [
              {
                type: 'images_url',
                urls: this.urlImageTemp
              }
            ]
        }
      ]
    }
    await ProductService.updateTitleProduct(data)
  }
}
